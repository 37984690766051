<script lang="ts">
    export let type = 'solid';
</script>

{#if type === 'solid'}
    <svg xmlns="http://www.w3.org/2000/svg" {...$$restProps} width="32" height="36" viewBox="0 0 32 36" fill="none">
        <rect x="0.5" y="0.5" width="14" height="14" rx="1.5" fill="currentColor" stroke="currentColor"></rect>
        <rect x="0.5" y="17.5" width="14" height="14" rx="1.5" fill="currentColor" stroke="currentColor"></rect>
        <rect x="17.5" y="6.5" width="14" height="29" rx="1.5" fill="currentColor" stroke="currentColor"></rect>
    </svg>
{:else}
    <svg xmlns="http://www.w3.org/2000/svg" {...$$restProps} width="32" height="36" viewBox="0 0 32 36" fill="none">
        <rect x="0.5" y="0.5" width="14" height="14" rx="1.5" stroke="currentColor"></rect>
        <rect x="0.5" y="17.5" width="14" height="14" rx="1.5" stroke="currentColor"></rect>
        <rect x="17.5" y="6.5" width="14" height="29" rx="1.5" stroke="currentColor"></rect>
    </svg>
{/if}
